.banner-carousel {
    overflow: hidden;
    width: 100%;
    .slick-list {
        width: 100%;
    }
    .slick-track {
        min-width: 100vw;
        width: 100%;
    }
    .slick-prev {
        left: 20px;
    }
    .slick-next {
        right: 20px;
    }
}
.banner-carousel .slick-elem:nth-child(n + 1) {
    display: none;
}
.banner-carousel.slick-initialized,
.banner-carousel .slick-elem:first-child {
    display: block;
}

.banner-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 100vw;
    //padding-bottom: 72%; // ratio 1920x560
    height: 460px;
    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.banner-overlay {
    background:
        linear-gradient(
            180deg,
            rgba($color: $black, $alpha: 0.5) 0%,
            rgba($color: $black, $alpha: 0) 50%,
            rgba($color: $black, $alpha: 0.5) 100%
        );
}

.header-banner {
    .btn:hover {
        color: $secondary !important;
        background-color: $white !important;
        border-color: $white !important;
    }
}

.home {
    .banner-image {
        height: 500px;
    }
    .banner-title {
        line-height: 1.2 !important;
    }
}

// .blog,
// .news,
// .events,
// .interactive,
// .about {
//     .banner-image {
//         height: 460px;
//     }
// }

.serach-banner {
    .banner-overlay{
        background: none;
    }
    .banner-image {
        height: 310px;
    }
}

.circle-dot {
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    display: inline-block;
    background-color: $success;
    border-radius: 50%;
}

@include media-breakpoint-up(md) {
    .banner-image {
        padding-bottom: 29.2%; // ratio 1920x560
    }
    .home {
        .banner-image {
            padding-bottom: 40.6%; // ratio 1920x780
        }
    }
    .blog,
    .news,
    .events,
    .interactive,
    .about {
        .banner-image {
            padding-bottom: 34.4%; // ratio 1920x660
        }
    }
}

@include media-breakpoint-up(lg) {
    .museum-banner-col{
        max-width: 494px !important;
        width: 100% !important;
    }
    .banner-carousel {
        .slick-prev {
            left: 4%;
            top: inherit;
            bottom: 0;
        }
        .slick-next {
            right: 85.5%;
            top: inherit;
            bottom: 0;
        }
    }
    .home {
        .banner-overlay {
            align-items: center !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    .banner-carousel {
        .slick-prev {
            left: 5%;
        }
        .slick-next {
            right: 86.5%;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .banner-carousel {
        .slick-prev {
            left: 6%;
            bottom: 1rem;
        }
        .slick-next {
            right: 87.5%;
            bottom: 1rem;
        }
    }
}

@media (min-width: 1800px) {
    .banner-carousel {
        .slick-prev {
            left: 12%;
        }
        .slick-next {
            right: 82.5%;
        }
    }
}

@include media-breakpoint-only(lg) {
    .banner-overlay {
        .banner-title {
            font-size: 6rem !important;
        }
    }

    .home {
        .banner-overlay {
            .banner-title {
                font-size: 6.5rem !important;
            }
        }
    }
}

@include media-breakpoint-only(md) {
    .banner-overlay {
        .banner-title {
            font-size: 5rem !important;
        }
    }
    .home {
        .banner-overlay {
            padding-bottom: 6rem !important;
            .banner-title {
                font-size: 5.5rem !important;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .banner-overlay {
        .banner-title {
            font-size: 4.5rem !important;
        }
    }
    .home {
        .banner-overlay {
            padding-bottom: 7rem !important;
            .banner-title {
                font-size: 5rem !important;
                line-height: 1.3 !important;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .banner-overlay {
        .banner-title {
            font-size: 4rem !important;
        }
    }
    .home {
        .banner-overlay {
            .banner-title {
                font-size: 4rem !important;
            }
        }
    }
}

@media (max-width: 360px){
    .banner-overlay {
        .banner-title {
            font-size: 3.5rem !important;
        }
    }
}