.notification-bar {
    z-index: 1;
    margin-top: -20px;
}
.pulsating-container {
    height: 90px;
    padding: 2.2rem 2rem 2.4rem 5rem;
}

.notifications {
    .btns {
        .link-secondary:first-child {
            margin-left: 0 !important;
        }
    }
}

.pulsating-circle {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 500%;
        height: 500%;
        top: calc(-250% + 4px);
        left: calc(-250% + 4px);
        border-radius: 50%;
        background-color: $secondary;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $secondary;
        border-radius: 15px;
        // box-shadow: 0 0 8px $dark;
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }
    80%,
    100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(0.7);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}

@include media-breakpoint-up(lg) {
    .notification-bar {
        position: absolute;
        margin-top: 0;
    }
    .pulsating-container {
        height: 75px;
        padding: 2rem 5rem 2rem 6.5rem;
    }
    .pulsating-circle {
        left: 40px;
    }
}
